<!-- 作业详情 已做-->
<style lang="scss" scoped>
@import '@/assets/styles/questions.scss';
.page{
	height: 100%;
	overflow: auto;
}
*{
	box-sizing: border-box;
}
.content{
	width: 100%;
	padding: 31px 0 10px;
	display: inline-block;
	position: relative;
	z-index: 5;
	.score{
		display: flex;
		margin-top: 20px;
		.grade{
			min-height: 130px;
			position: relative;
			padding-top: 30px;
			text-align: center;
			width: 200px;
			margin-right: 20px;
			.gradebg{
				position: absolute;
				left: 50%;
				top: 0;
				width: 100%;
				max-height: 200px;
				transform: translate(-50%, 0%);
				z-index: 0;
			}
			.number{
				position: relative;
				z-index: 4;
				height: 34px;
				font-size: 28px;
				font-weight: bold;
				color: #FFFFFF;
				line-height: 34px;
				text-align: center;
				width: 100%;
			}
			.text{
				font-size: 18px;
				font-weight: bold;
				color: #FFFFFF;
				line-height: 25px;
				position: relative;
				z-index: 4;
				margin-top: 6px;
			}
		}
		.teacherRemark{
			position: relative;
			width: 100%;
			padding: 10px 10px;
			background-color: #FEF5E4;
			color: #FF7C12;
			font-size: 16px;
			line-height: 36px;
			text-align: left;
			border-radius: 5px;
			flex: 1;
			.shangjiantou{
				position: absolute;
				width: 0;
				height: 0;
				left: -20px;
				top: 50%;
				border-right: 20px solid #FEF5E4;
				border-top: 20px solid transparent;
				border-bottom: 20px solid transparent;
				margin-top: -20px;
				display: none;
			}
		}
	}
	.testPaper{
		padding: 20px;
		width: 100%;
		background: #FDFDFD;
		box-shadow: 0px 14px 40px -17px #B7D0DE;
		border-radius: 14px;
		position: relative;
		.excellent{
			position: absolute;
			right: 20px;
			top: 70px;
			width: 80px;
			height: 80px;
		}
		.name{
			height: 33px;
			font-size: 18px;
			font-weight: 400;
			color: #444444;
			line-height: 33px;
		}
		.teacherInfo{
			font-size: 16px;
			font-weight: 400;
			color: #888888;
			line-height: 28px;
			.avatar{
				width: 26px;
				height: 26px;
				border-radius: 50%;
				margin-right: 7px;
			}
		}
		.sthom_title{
			font-size: 16px;
			font-weight: 400;
			color: #333333;
			line-height: 36px;
		}
		.endDate{
			height: 28px;
			font-size: 14px;
			font-weight: 400;
			color: #01A1FD;
			line-height: 28px;
			background: rgba(1, 161, 253, 0.1);
			border-radius: 100px;
			padding: 0 10px;
	
		}
		.examine{
			width: 70px;
			height: 28px;
			border-radius: 100px;
			line-height: 28px;
			font-size: 14px;
			margin-left: 10px;
			text-align: center;
			&.type1{
				background: rgba(255,124,18,0.1);
				color: $comOrange;
			}
			&.type2{
				background: rgba(170,170,170,0.1);
				color: $comAAA;
			}
			&.type3{
				background: #FDEBEB;
				color: $comRed;
			}
		}
		.btn{
			width: 128px;
			height: 64px;
			border-radius: 14px;
			color: #FFFFFF;
			font-size:28px;
			line-height: 64px;
			text-align: center;
			&.type1{
				background: $comBlue;
			}
			&.type2{
				background: $comGray;
				color: #888888;
			}
		}
		.title{
			margin-top: 12px;
			font-size: 14px;
			font-weight: 400;
			color: #444444;
			line-height: 24px;
			padding-right: 100px;
		}
		.remark{
			margin-top: 24px;
			width: 100%;
			background-color: #FFFFFF;
			border-radius: 14px;
			position: relative;
			display: inline-block;
			.iconimg{
				position: absolute;
				left: 0px;
				top: 0px;
				width: 48px;
				height: 45px;
			}
			.teacherRemark{
				width: 160px;
				height: 52px;
				background: rgba(255,124,18,0.102);
				border-radius: 0px 30px 30px 0px;
				line-height: 52px;
				text-align: center;
				font-size: 28px;
				color: $comOrange;
				font-weight: bold;
				margin-top: 13px
			}
			.value{
				font-size: 28px;
				line-height: 36px;
				color: $comOrange;
				margin-top: 13px;
			}
		}
	}
}
.photograph {
	margin-top: 20px;

	.h3 {
		height: 34px;
		font-size: 18px;
		font-weight: bold;
		color: #222222;
		line-height: 34px;
	}

	.images {
		margin-top: 24px;
		scroll-snap-type: x mandatory;
		width: 100%;
		display: flex;
		overflow-x: scroll;
		overflow-y: hidden;
		padding-bottom: 5px;
		.el-image{
			margin-right: 10px;
			flex-shrink: 0;
			&:last-child {
				margin-right: 0;
			}
		}
	}
}
  .questions .topic{
	margin-top: 0 !important;
  }
  .questions .li .li,.questions .group .li .li{
	margin-top: 10px;
	margin-bottom: 0px;
	background: #f6fcf9;
  }
  .questions .group .li{
	background-color: #FFFFFF;
	margin-top: 0px;
  }
</style>

<template>
    <div class="page">
		<div class="content">
			<div class="testPaper">
				<!-- 优秀 -->
				<img class="excellent" v-if="homework_info.sthom_wor_isexcellent==10" src="@assets/images/excellent.png" alt="">
				<div class="flex_bet_align" v-if="homework_info.sysub_name">
					<div class="flex_1">
						<div class="flex_bet_align">
							<div class="name">{{homework_info.sysub_name}}作业</div>
							<div class="teacherInfo flex_align">
								<img class="avatar" v-if="homework_info.teuse_image" :src="homework_info.teuse_image" alt="">
								{{homework_info.teuse_name}}
							</div>
						</div>
						<div class="sthom_title">
							{{homework_info.sthom_wor_title}}
						</div>
						<div class="flex_align mt-16">
							<div v-if="accomplish" class="endDate">截止时间：{{homework_info.sthom_wor_deadlinetime}}前</div>
							<div class="examine type1" v-if="homework_info.sthom_wor_status==30">已批阅</div>
							<div class="examine type2" v-if="homework_info.sthom_wor_status==10">待批阅</div>
							<div class="examine type3" v-if="homework_info.sthom_wor_status==20">待完成</div>
						</div>
					</div>
				</div>
				<div class="title">{{homework_info.sthom_wor_remark}}</div>
			</div>
			<!-- 评分 -->
			<div class="score" v-if="homework_info.sthom_wor_status==30">
				<div class="grade">
					<img class="gradebg" src="@assets/images/studentpinfenbg.png" alt="">
					<div class="number">{{homework_info.sthom_wor_show==10?homework_info.sthom_wor_score:homework_info.sthom_wor_grade}}</div>
					<div class="text">{{homework_info.sthom_wor_show==10?"评分":"评级"}}</div>
				</div>
				<div class="teacherRemark" v-if="homework_info.sthom_wor_comment">
					<div class="shangjiantou"></div>
					{{homework_info.sthom_wor_comment?homework_info.sthom_wor_comment:""}}
				</div>
			</div>
			
			<!-- 拍照照片-->
			<div class="photograph" v-if="homework_info.sthom_wor_answerimg">
				<div class="h3">答题图片</div>
				<div class="images">
					<el-image style="width: 100px; height: 100px" :src="item" :preview-src-list="[item]" v-for="(item,index) in homework_info.sthom_wor_answerimg" :key="index"></el-image>
				</div>
			</div>
			<!--  :class="homework_info.sthom_wor_status!=30?'incomplete':''" -->
			<div class="questions">
				<div v-for="(item,index) in homework_list" :key="index">
					<div class="type flex_align" v-if="index==0">{{item.syque_typ_name}}</div>
					<!-- 判断本题目的标题是否和上一题一样，一样就不展示标题 -->
					<div class="type flex_align" v-else-if="homework_list[index-1].syque_typ_name!=item.syque_typ_name">{{item.syque_typ_name}}</div>
					<div class="li" @click="goDetails(index,item)">
						<div class="topic">
							<div class="flex">
								<!-- <div class="number">{{index+1}}、</div> -->
								<div class="val">
									<div class="richinline" v-html="item.sthom_que_title"></div>
								</div>
							</div>
							<img class="selectimg" v-if="item.sthom_que_image" :src="item.sthom_que_image" alt="">
							<!-- 上传的图片答题 显示需要选择的答案 -->
							<div v-if="homework_info.sthom_wor_answer_type==2&&(item.syque_typ_id==1||item.syque_typ_id==2||item.syque_typ_id==5)">
								<div v-for="(item2,index2) in item.question_item" :key="index2">
									<div class="li flex">
										<div class="number">{{item2.sthom_que_ite_code}}.</div>
										<div class="flex_1">
											<div class="flex_1"><span class="richinline" v-html="item2.sthom_que_ite_title"></span></div>
											<img class="selectimg" v-if="item2.sthom_que_ite_image" :src="item2.sthom_que_ite_image" alt="">
											<!-- <el-image v-if="item2.sthom_que_ite_image" style="width: 100px; height: 100px" :src="item2.sthom_que_ite_image" :preview-src-list="[item2.sthom_que_ite_image]"></el-image> -->
										</div>
									</div>
								</div>
							</div>
						</div>
						<!-- sthom_wor_answer_type上传的图片答题 -->
						<div v-if="homework_info.sthom_wor_answer_type!=2">
							<!-- syque_typ_id   1单项选择 2多项选择 3单项填空 4多项填空 5判断 6计算 7简答 8问答 -->
							<div v-if="item.syque_typ_id==1||item.syque_typ_id==5">
								<div class="questionType">
									<div class="answer" :class="item.sthom_que_isstustatus==20?'mistake':''">
										<div class="title">你的答案:</div>
										<div class="flex_1 flex">
											<div v-if="item.sthom_que_stuanswer">
												<div v-for="(itemti,indexti) in item.sthom_que_stuanswer" :key="indexti" class="val">{{indexti>0?',':''}}<span class="richinline" v-html="itemti?itemti:'__'"></span></div>
											</div>
											<div class="iconfont dui" v-if="item.sthom_que_isstustatus==10">&#xe6db;</div>
											<div class="iconfont cuo" v-if="item.sthom_que_isstustatus==20">&#xe6dc;</div>
										</div>
									</div>
								</div>
							</div>
							<!-- 多选题 -->
							<div v-if="item.syque_typ_id==2">
								<div class="questionType">
									<!-- class=answer mistake 错 -->
									<div class="answer" :class="item.sthom_que_isstustatus==20?'mistake':''">
										<div class="title">你的答案:</div>
										<div class="flex_1 flex">
											<template v-if="item.sthom_que_stuanswer">
												<div  v-for="(itemti,indexti) in item.sthom_que_stuanswer" :key="indexti" class="val">{{indexti>0?',':''}}<span class="richinline" v-html="itemti?itemti:'__'"></span> </div>
											</template>
											<div class="iconfont dui" v-if="item.sthom_que_isstustatus==10">&#xe6db;</div>
											<div class="iconfont cuo" v-if="item.sthom_que_isstustatus==20">&#xe6dc;</div>
										</div>
									</div>
								</div>
							</div>
							<!-- 填空题 -->
							<div v-if="item.syque_typ_id==3||item.syque_typ_id==4">
								<div class="questionType">
									<!-- class=answer mistake 错 -->
									<div class="answer" :class="item.sthom_que_isstustatus==20?'mistake':''">
										<div class="title">你的答案:</div>
										<div class="flex_1 flex">
											<div>
												<div v-if="item.sthom_que_stuanswer||item.sthom_que_stuanswer_image">
													<template v-if="item.sthom_que_stuanswer">
														<div v-for="(itemti,indexti) in item.sthom_que_stuanswer" :key="indexti">
															<div class="val" v-if="itemti">
																<div class="richblock" v-html="itemti"></div>
															</div>
														</div>
													</template>
													<div v-if="item.sthom_que_stuanswer_image">	
														<img class="selectimg" v-if="item.sthom_que_stuanswer_image" :src="item.sthom_que_stuanswer_image" alt="">
														<!-- <el-image style="width: 100px; height: 100px" :src="item.sthom_que_stuanswer_image" :preview-src-list="[item.sthom_que_stuanswer_image]"></el-image> -->
													</div>
												</div>
											</div>
											<div class="iconfont dui" v-if="item.sthom_que_isstustatus==10&&homework_info.sthom_wor_status==30">&#xe6db;</div>
											<div class="iconfont cuo" v-if="item.sthom_que_isstustatus==20&&homework_info.sthom_wor_status==30">&#xe6dc;</div>
										</div>
									</div>
								</div>
							</div>
							<!-- 阅读题 -->
							<div v-if="item.syque_typ_id==7">
								<div class="questionType">
									<div class="answer" :class="item.sthom_que_isstustatus==20?'mistake':''">
										<div class="title">你的答案:</div>
										<div class="flex_1 flex">
											<div>
												<div v-if="item.sthom_que_stuanswer.length||item.sthom_que_stuanswer_image">
													<template v-if="item.sthom_que_stuanswer.length">
														<div v-for="(itemti,indexti) in item.sthom_que_stuanswer" :key="indexti" class="val" v-if="itemti">{{indexti>0?',':''}}<span class="richinline" v-html="itemti"></span></div>
													</template>
													<div v-if="item.sthom_que_stuanswer_image">
														<img class="selectimg" v-if="item.sthom_que_stuanswer_image" :src="item.sthom_que_stuanswer_image" alt="">
														<!-- <el-image style="width: 100px; height: 100px" :src="item.sthom_que_stuanswer_image" :preview-src-list="[item.sthom_que_stuanswer_image]"></el-image> -->
													</div>
												</div>
												<div v-else>
													<div class="val">未作答</div>
												</div>
											</div>
											<div class="iconfont dui" v-if="item.sthom_que_isstustatus==10&&homework_info.sthom_wor_status==30">&#xe6db;</div>
											<div class="iconfont cuo" v-if="item.sthom_que_isstustatus==20&&homework_info.sthom_wor_status==30">&#xe6dc;</div>
										</div>
									</div>
								</div>
							</div>
						</div>	
						<iJudge v-if="!school&&accomplish&&homework_info.sthom_wor_status==30&&item.syque_typ_id!=14&&homework_info.sthom_wor_answer_type==2" :item="item" :index1="index" :typeId="item.syque_typ_id" @update_question="update_question"></iJudge>
						<!-- 组合题 -->
						<div v-if="item.syque_typ_id==14" class="group">
							<div v-for="(item2,index2) in item.childrenQuestion" :key="index2">
								<div class="li">
									<div class="topic">
										<!-- <div class="number">{{index2+1}}、</div> -->
										<div class="val">
											<div class="richinline" v-html="item2.sthom_que_title"></div>
										</div>
										<img class="selectimg" v-if="item2.sthom_que_image" :src="item2.sthom_que_image" alt="">
										<!-- <el-image v-if="item2.sthom_que_image" style="width: 100px; height: 100px" :src="item2.sthom_que_image" :preview-src-list="[item2.sthom_que_image]"></el-image> -->
									</div>
									<!-- 上传的图片答题 显示需要选择的答案 -->
									<div v-if="homework_info.sthom_wor_answer_type==2&&(item2.syque_typ_id==1||item2.syque_typ_id==2||item2.syque_typ_id==5)">
										<div v-for="(item3,index2) in item2.question_item" :key="index2">
											<div class="li flex">
												<div class="number">{{item3.sthom_que_ite_code}}.</div>
												<div class="flex_1">
													<div class="flex_1"><span class="richinline" v-html="item3.sthom_que_ite_title"></span></div>
													<img class="selectimg" v-if="item3.sthom_que_ite_image" :src="item3.sthom_que_ite_image" alt="">
													<!-- <el-image v-if="item3.sthom_que_ite_image" style="width: 100px; height: 100px" :src="item3.sthom_que_ite_image" :preview-src-list="[item3.sthom_que_ite_image]"></el-image> -->
												</div>
											</div>
										</div>
									</div>
									<!-- sthom_wor_answer_type上传的图片答题 -->
									<div v-if="homework_info.sthom_wor_answer_type!=2">
										<div class="questionType">
											<div class="answer" :class="item2.sthom_que_isstustatus==20?'mistake':''">
												<div class="title">你的答案:</div>
												<div class="flex_1">
													<div class="flex_1 flex">
														<div>
															<div v-if="item2.sthom_que_stuanswer.length||item2.sthom_que_stuanswer_image">
																<div v-if="item2.sthom_que_stuanswer.length">
																	<div v-for="(itemti,indexti) in item2.sthom_que_stuanswer" :key="indexti">
																		<div class="val" v-if="itemti">
																			<div class="richblock" v-html="itemti"></div>
																		</div>
																	</div>
																</div>
																<div v-if="item2.sthom_que_stuanswer_image">
																	<img class="selectimg" v-if="item2.sthom_que_stuanswer_image" :src="item2.sthom_que_stuanswer_image" alt="">
																	<!-- <el-image style="width: 100px; height: 100px" :src="item2.sthom_que_stuanswer_image" :preview-src-list="[item2.sthom_que_stuanswer_image]"></el-image> -->
																</div>
															</div>
															<div v-else>
																<div class="val">未作答</div>
															</div>
														</div>
														<div class="iconfont dui" v-if="item2.sthom_que_isstustatus==10&&homework_info.sthom_wor_status==30">&#xe6db;</div>
														<div class="iconfont cuo" v-if="item2.sthom_que_isstustatus==20&&homework_info.sthom_wor_status==30">&#xe6dc;</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<iJudge v-if="!school&&accomplish&&homework_info.sthom_wor_status==30&&homework_info.sthom_wor_answer_type==2" :item="item2" :index1="index" :index2="index2" :typeId="item.syque_typ_id" @update_question="update_question"></iJudge>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { homework_info , mark_error_question} from "@api/home"
import iJudge from './components/iJudge.vue'
export default {
    name: 'jobDetails',
	components: {
		iJudge
	},
    data() {
        return {
            //作业详情
            homework_info:{},
            homework_list:{},
            sthom_wor_id:0,
			// 是否是校内课程
			school:false,
			//是否显示完成状态 校内作业为false不显示
			accomplish:true
        }
    },
    created() {
        this.sthom_wor_id=this.$route.params.id
        this.school=this.$route.params.school==1
        this.accomplish=this.$route.params.accomplish==1
        this.getData();
		this.$forceUpdate();
    },
    methods: {
       
        async getData() {
            let { data } = await homework_info({sthom_wor_id:this.sthom_wor_id});
            this.homework_info=data
            this.homework_list=data.question
            this.$forceUpdate();
        },
        //跳转提
        goDetails(index,item){
            //已经批阅的跳转到查看解析详情
            if(this.homework_info.sthom_wor_status==30){
				this.$router.push({
					name:this.school?"COURSE_DETAILS_ITEM":"JOB_DETAILS_ITEM",
					params: {
					index: index,
					id:this.sthom_wor_id,
					sthom_wor_answer_type:this.homework_info.sthom_wor_answer_type
					}
				})
            }
        },
        //判断试题是否选中 answer答案  list
        getopt(answer,list){
            if(answer&&list&&list.length>0){
                return list.includes(answer)
            }else{
                return false
            }
        },
      
		//手动标识题目状态
		async update_question({item,index1,index2,typeId}){
			let res = await mark_error_question({
				sthom_que_id:item.sthom_que_id,
				type:item.sthom_que_isstustatus
			});
			// 组合提
			if(typeId==14){
				this.homework_list[index1].childrenQuestion[index2]=item
			}else{
				this.homework_list[index1]=item
			}
            this.$forceUpdate();
		},
    },
}
</script>