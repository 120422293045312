<!-- 线下家庭作业自己判断对错 -->
<template>
	<div class="judge">
		<div v-if="item.sthom_que_ismark == 2" class="flex_around_align">
			<div class="mistake flex_content_center" @click.stop="update_question(20)">
				<div class="iconfont">&#xe6dc;</div>回答错误
			</div>
			<div class="correct flex_content_center" @click.stop="update_question(10)">
				<div class="iconfont">&#xe6db;</div>回答正确
			</div>
		</div>
		<div v-else class="flex_around_align">
			<div class="combtn5" style="width:300px;height:60px" v-if="item.sthom_que_isstustatus==10">
				<div class="iconfont">&#xe6db;</div>回答正确
			</div>
			<div class="combtn4 c-red" style="width:300px;height:60px;" v-if="item.sthom_que_isstustatus==20">
				<div class="iconfont c-red">&#xe6dc;</div>回答错误
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'i-judge',
		props: {
			item: {
				type: Object,
			},
			index1: {
				type: Number,
			},
			index2: {
				type: Number,
			},
			typeId: {
				type: Number,
			}
			
		},
		methods: {
			update_question(isstustatus){
				// item整体内容 
				let item=this.item
				item.sthom_que_ismark = 1,
				item.sthom_que_isstustatus=isstustatus
				this.$emit('update_question',{item:item,index1:this.index1,index2:this.index2,typeId:this.typeId})
			},
		},
		
	}
</script>
<style lang="scss" scoped>
	.judge{
		padding: 10px 0px;
		width: 400px;
		margin: 0 auto;
		.iconfont{
			color: #FFFFFF;
			font-size: 16px;
			margin-right: 10px;
		}
		.correct{
			width: 130px;
			height: 50px;
			background-color: #01A1FC;
			color: #FFFFFF;
			border-radius: 5px;
			cursor: pointer;
		}
		.mistake{
			width: 130px;
			height: 50px;
			background-color: #FF4D4F;
			color: #FFFFFF;
			border-radius: 5px;
			cursor: pointer;
		}
		.combtn4,.combtn5{
			height: 50px !important;
			border-radius: 5px;
			cursor: pointer;
		}
	}
	.flex_around_align {
		display: flex;
		justify-content: space-around;
		align-items: center;
	}
</style>